// import * as React from 'react';
import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Button, Modal } from "react-bootstrap";
// reactstrap components
import {  Row, Col, } from "reactstrap";
import AxiosContext from '../store/axios-context';
import { useHistory } from 'react-router';
import AuthContext from '../store/auth-context';
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
import InputField from "./Fields/InputField";
import InputDate from "./Fields/InputDate";
import Dropdown from "./Fields/Dropdown";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function createData(id, jobType, project, party, status, action) {
    return {
        id,
        jobType,
        project,
        party,
        status,
        action,
    };
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'Invoice Number',
    },
    {
        id: 'jobType',
        numeric: false,
        disablePadding: false,
        label: 'Job Type',
    },
    {
        id: 'project',
        numeric: false,
        disablePadding: false,
        label: 'Project',
    },
    {
        id: 'party',
        numeric: false,
        disablePadding: false,
        label: 'Party',
    },
    {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'amount',
        numeric: false,
        disablePadding: false,
        label: 'Amount',
    },
    {
        id: 'received-amount',
        numeric: false,
        disablePadding: false,
        label: 'Received Amount',
    },
    {
        id: 'payment-reference',
        numeric: false,
        disablePadding: false,
        label: 'Payment Reference',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: '',
        numeric: false,
        disablePadding: false,
        label: '',
    },
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort
    } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell className="font-weight-bold"
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <span></span>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function Invoice() {
    const history = useHistory();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [formName, setFormName] = React.useState('Create');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // const [rows, setRows] = useState([]);
    const [filters, setFilters] = useState(0);
    const [invoices, setInvoices] = useState([]);
    const [invoiceIds, setInvoiceIds] = useState([]);
    const [totalInvoices, setTotalInvoices] = useState(0);
    const axiosCtx = useContext(AxiosContext);
    const authCtx = useContext(AuthContext);
    const [formError, setFormError] = useState(null);
    const [paymentReference, setPaymentReference] = useState('');
    const [filterInvoicenumber, setFilterInvoicenumber] = useState('');
    const getInvoices = () => {
        setFilters(0);
        setLoading(true);
        const params = {
          page: page + 1,
          perPage: rowsPerPage
        };
        axiosCtx.instance.get(`invoice`,{ params })
            .then((response) => {
                // console.log(response.data.data);
                setLoading(false);
                console.log('job',response.data)
                setInvoices(response.data.data);
                // setRows(response.data.data);
                // setTotalInvoices(response.data.totalInvoices);
            })
            .catch((error) => {
                // setFormError(error.response.data);
                // setLoading(false);
            });
    };

    const getInvoiceIds = () => {
     
        axiosCtx.instance.get(`invoiceIds`)
            .then((response) => {
                console.log('invoiceids',response.data.data);
                setLoading(false);
                
                setInvoiceIds(response.data.data);
               
            })
            .catch((error) => {
                // setFormError(error.response.data);
                // setLoading(false);
            });
    };
    const getJobIds = () => {
     
        axiosCtx.instance.get(`jobIds`)
            .then((response) => {
                console.log('jobIds',response.data.data);
                setLoading(false);
                
                setJobIds(response.data.data);
               
            })
            .catch((error) => {
                // setFormError(error.response.data);
                // setLoading(false);
            });
    };

    useEffect(() => {
        if (authCtx.isLoggedIn == false) {
            history.replace('/auth/Login');
        }
        if(filters ==0){
            getInvoices();
        }else{
            filterSubmit();
        }
       getInvoiceIds();
       getJobIds();
        getJobs();
        getParties();
        getProjects();
        getCurrency();
        getStatues();
        getJobtypes();
    }, [page, rowsPerPage, filters]);

    const onPrintClickHandler = (item) => {
        // history.push({
        //     pathname: `invoice-pdf/${item.id}`,
        //     state: {}
        // });
        const pdfUrl = `/invoice-pdf/${item.id}`;
        window.open(pdfUrl, '_blank'); 
    };

    const onDeleteClickHandler = (item) => {
        setDeleteInvoiceId(item.id);
        handleShowDelete();
    }

    const deleteSubmitHandler = () => {
        setLoading(true);
        if (deleteInvoiceId) {
            console.log(deleteInvoiceId);
            axiosCtx.instance.delete(`invoice/` + deleteInvoiceId,)
                .then((response) => {
                    setLoading(false);
                    setDeleteMessage(response.data.data);
                    setDeleteInvoiceId('');
                    getInvoices();
                    getInvoiceIds();
                    handleCloseDelete();
                })
                .catch((error) => {
                    setDeleteMessage(error.response.data.errors);
                    setLoading(false);
                });
        }
    };

    const [showDelete, setShowDelete] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [deleteInvoiceId, setDeleteInvoiceId] = useState('');
    const handleCloseDelete = () => {
        setShowDelete(false);
        setDeleteMessage('');
        setDeleteInvoiceId('');
    }
    const handleShowDelete = () => setShowDelete(true);

    const onEditClickHandler = (item) => {
        history.push({
            pathname: `invoice-edit/${item.id}`,
            state: {
            }
        });
        // setFormError(null);
        // setFormName('Edit');
        // const invoiceRequest = axiosCtx.instance.get(`invoice/${item.id}`);
        // const costsRequest = axiosCtx.instance.get(`cost/job/${item.job.id}`);

        // Promise.all([costsRequest, invoiceRequest]).then(([costsResponse, invoicesResponse]) => {

        //     if (costsResponse.status === 200) {
        //         setCosts(() => costsResponse.data.data === undefined ? [] : costsResponse.data.data);
        //     }

        //     if (invoicesResponse.status === 200) {
        //         let response = invoicesResponse.data.data;
        //         console.log(response);
        //         setInvoiceId(response.id);
        //         setSelectedParty(response.party.id);
        //         setSelectedJob(response.job.id);
        //         setSelectedProject(response.project.id);
        //         setInvoiceCurrency(response.currency.id);
        //         setInvoiceDate(response.invoice_date);
        //         // setDestination(response.destination.id);
        //         setOurReference(response.our_reference);
        //         // setShowDestination(true);    
        //         setShow(true);
        //     }

        // }).catch((error) => {
        //     // TODO: Handle Error
        //     console.log(error)
        //     alert('Sorry, something went wrong!');
        // });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = invoices.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        event.stopPropagation();

        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const paymentReferenceHandleChange = (event, id) => {
        const updatedRows = invoices.map(row => {
            if (row.id === id) {
                return { ...row, payment_reference: event.target.value };
            }
            return row;
        });
        setInvoices(updatedRows);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const getCostsOfJob = (jobId) => {
        const costsRequest = axiosCtx.instance.get(`cost-invoice/job/${jobId}`);
        const jobRequest = axiosCtx.instance.get(`job/${jobId}`);

        Promise.all([costsRequest, jobRequest]).then(([costsResponse, jobsResponse]) => {

            if (costsResponse.status === 200) {
                setCosts(() => costsResponse.data.data === undefined ? [] : costsResponse.data.data);
            }

            if (jobsResponse.status === 200) {
                let response = jobsResponse.data.data[0];
                setSelectedParty(response.party);
                // setSelectedJob(response.id);
                setSelectedProject(response.project);
            }

        }).catch((error) => {
            // TODO: Handle Error
            alert('Sorry, something went wrong!');
        });
    };

    const findMultipleAirwaybills = (jobId) => {
        const destination = axiosCtx.instance.get(`cost/find-multiple-airwaybills/${jobId}`);

        Promise.all([destination]).then(([destinationResponse]) => {
            if (destinationResponse.status === 200) {
                setPorts(destinationResponse.data.data);
                setShowDestination(true);
            }

        }).catch((error) => {
            // TODO: Handle Error
        });
    };

    const jobChangeHandler = (event, value) => {
        setShowDestination(false);
        if (value) {
            getCostsOfJob(value.id);
            findMultipleAirwaybills(value.id);
            setSelectedJob(value.id);
            setSelectedParty('');
            setSelectedProject('');
        }

    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onDeleteCost = (index, i) => {
        let data = [...costs];
        delete data[index].costs[i];
        setCosts(data);
    }

    const updateStatus = async (id, statusId) => {
        try {
            axiosCtx.instance.put(`invoice/update-status`, {invoice_id:id, status_id: statusId })
            .then((response) => {
               console.log(response);
               getInvoices();
            })
            .catch((error) => {
                
                setFormError(error);

            });
            // Optionally, update the local state or trigger a data reload
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handlePaymentReferenceBlur = async (id, paymentReference) => {
        console.log("ID: ", id, "Payment Reference: ", paymentReference);
        if (!id || !paymentReference) {
            console.error("Invalid ID or payment reference");
            return;
        }
        try {
            const response = axiosCtx.instance.put(`invoice/update-paymentReference`, {
                invoice_id: id,
                payment_reference: paymentReference
            });
            console.log(response);
            getInvoices();  // Optionally reload data
        } catch (error) {
            console.error("Error:", error);
            setFormError(error);
        }
    };
    const onInvoiceSubmit = () => {
        setFormError(null);
        setLoading(true);
        // Call login API
        const params = {
            job_id: selectedJob,
            project_id: selectedProject,
            party_id: selectedParty,
            invoice_date: invoiceDate,
            currency_id: invoiceCurrency,
            destination: destination,
            our_reference: ourReference,
            costs_fields: JSON.stringify(costs)
        }

        if (invoiceId) {
            axiosCtx.instance.put(`invoice/` + invoiceId, params)
                .then((response) => {
                    setLoading(false);
                    getInvoices();
                    getInvoiceIds();
                    handleClose();
                })
                .catch((error) => {
                    setLoading(false);
                    setFormError(error.response.data);

                });
        } else {

            const formData = new FormData();
            formData.append("job_id", (selectedJob) ? selectedJob.id : '' );
            formData.append("project_id", (selectedProject) ? selectedProject.id : '');
            formData.append("party_id", (selectedParty) ? selectedParty.id : '');
            formData.append("invoice_date", invoiceDate);
            formData.append("currency_id", (invoiceCurrency) ? invoiceCurrency.id : '');
            formData.append("destination", (destination) ? destination.id : '');
            formData.append("our_reference", ourReference);
            formData.append("amount", parseFloat(totalAmountAfterVat).toFixed(2));
            formData.append("costs_fields", JSON.stringify(costs));

            axiosCtx.instance.post(`invoice`, formData)
                .then((response) => {
                    setLoading(false);
                    getInvoices();
                    getInvoiceIds();
                    handleClose();
                })
                .catch((error) => {
                    setLoading(false);
                    setFormError(error.response.data);

                });
        }

    }

    const [startDate, setStartDate] = React.useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setFormName('Create');
        setInvoiceId('');
        setSelectedParty('');
        setSelectedJob('');
        setSelectedProject('');
        setInvoiceCurrency('');
        setInvoiceDate('');
        setStartDate('');
        setEndDate('');
        setOurReference('');
        setDestination('');
        setShowDestination(false);
    }
    const handleShow = () => { setShow(true); setFormError(null); }
    const [showFilter, setShowFilter] = useState(false);
    // const filterClose = () => setShowFilter(false);

    const filterClose = () => {
        setShowFilter(false);
        // setStatus('');
        // setJobtype('');
        // setProject('');
        // setParty('');
        // getInvoices();
    }

    const filterClear = () => {
        setFilters(0);
        setFilterInvoicenumber(null);
        setInvoiceDate('');
        setStartDate('');
        setEndDate('');
        setStatus(null);
        setProject(null);
        setJobtype(null);
        setParty(null);
        getInvoices();
    }

    const [jobtypes, setJobtypes] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [jobIds, setJobIds] = useState([]);
    const [parties, setParties] = useState([]);
    const [projects, setProjects] = useState([]);
    const [currencys, setCurrency] = useState([]);
    const [costs, setCosts] = useState(null);
    const [showDestination, setShowDestination] = useState(false);
    const [ports, setPorts] = useState([]);
    const [destination, setDestination] = useState(null);
    const [ourReference, setOurReference] = useState('');

    const [selectedParty, setSelectedParty] = useState('');
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedJob, setSelectedJob] = useState('');
    const [invoiceId, setInvoiceId] = useState('');

    const [status, setStatus] = useState('');
    const [statuses, setStatuses] = useState([]);
    const [party, setParty] = useState('');
    const [jobtype, setJobtype] = useState('');
    const [project, setProject] = useState('');

    const [conversionRate, setConversionRate] = useState([]);

    // const currencyOptions = currencys.map(({
    //     currency_label: label,
    //     ...rest
    //   }) => ({
    //     label,
    //     ...rest
    //   }));

    const isSelected = (name) => selected.indexOf(name) !== -1;
    const [age, setAge] = React.useState('');
    const [invoiceDate, setInvoiceDate] = React.useState('');
    
      const [endDate, setEndDate] = React.useState('');
    const [invoiceCurrency, setInvoiceCurrency] = React.useState('');
    const handleChange = (event) => { setAge(event.target.value); };
    const dateHandleChange = (event) => { setInvoiceDate(event.target.value); };
    const setSelectedDestination = (event) => { setDestination(event.target.value); };
    const currencyHandleChange = (event) => { setInvoiceCurrency(event.target.value); };

    const statusChangeHandler = (event, value) => {
        if (value) {
            setStatus(value.id);
        } else {
            setStatus('');
        }
    };
    const isFloat = (n) => {
        return Number(n) === n && n % 1 !== 0;
    }

    const ourReferenceHandleChange = (event) => {
        setOurReference(event.target.value);
    };
    const partyChangeHandler = (event, value) => {
        if (value) {
            setParty(value.id);
        } else {
            setParty('');
        }
    };
    const projectChangeHandler = (event, value) => {

        if (value) {
            setProject(value.id);
        } else {
            setProject('');
        }
    };

    const jobtypeChangeHandler = (event, value) => {

        if (value) {
            setJobtype(value.id);
        }
        else {
            setJobtype('');
        }
    };

    const getStatues = () => {
        axiosCtx.instance.get(`status`)
            .then((response) => {
                setStatuses(response.data.data);
            })
            .catch((error) => {
            });
    };

    const getJobtypes = () => {
        axiosCtx.instance.get(`job-type`)
            .then((response) => {
                setJobtypes(response.data.data);
            })
            .catch((error) => {
            });
    };

    const filterSubmit = (event) => {
        // event.preventDefault();
        setFormError(null);
        setLoading(true);
        const params = {
            page: page + 1,
            perPage: rowsPerPage
          };
        // Call login API
        const formData = new FormData();
        formData.append("invoice_number", (filterInvoicenumber) ? filterInvoicenumber.invoice_number : '');
        formData.append("job_type_id", (jobtype) ? jobtype.id : '');
        formData.append("status_id", (status) ? status.id : '');
        formData.append("project_id", (project) ? project.id : '');
        formData.append("party_id", (party) ? party.id : '');
        formData.append("invoice_date", invoiceDate);
        formData.append("date_of_execution_start", startDate);
    formData.append("date_of_execution_end", endDate);
        formData.append("page", page + 1);
        formData.append("perPage", rowsPerPage);
        setFilters(1);
        axiosCtx.instance.post(`invoice/filter`, formData)
            .then((response) => {
                
                console.log(response.data.data);
                setInvoices(response.data.data);
                 // setTotalInvoices(response.data.totalInvoices);
                // console.log(response.data.totalInvoices);
                setLoading(false);
                filterClose();
            })
            .catch((error) => {
                setLoading(false);
                setFormError(error.response.data);
            });
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - invoices.length) : 0;

    const getJobs = () => {
        axiosCtx.instance.get(`job`)
            .then((response) => {
                setJobs(response.data.data);

            })
            .catch((error) => {
            });
    };

    const getParties = () => {
        axiosCtx.instance.get(`party`)
            .then((response) => {
                setParties(response.data.data);
            })
            .catch((error) => {
            });
    };

    const getProjects = () => {
        axiosCtx.instance.get(`project`)
            .then((response) => {
                setProjects(response.data.data);

            })
            .catch((error) => {
            });
    };

    const getCurrency = () => {
        axiosCtx.instance.get(`currency`)
            .then((response) => {
                setCurrency(response.data.data);

            })
            .catch((error) => {
            });
    };

    const getCurrencyConvertion = (currency_label) => {
        setLoading(true);
        axiosCtx.instance.get(`get-by-currency/` + currency_label)
            .then((response) => {
                setConversionRate(JSON.parse(response.data.data));
                setLoading(false);

            })
            .catch((error) => {
                setLoading(false);
            });
    };

    let totalAmountBeforeVat = 0;
    let totalVatAmount = 0;
    let totalAmountAfterVat = 0;

    let con_revenue_amount = 0;
    let con_revenue_total = 0;

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#2f67c9");

    return (
        <div className="content">
            {loading && loading == true ?
                <div className="custom-loader">
                    <FadeLoader color={color} loading={loading} css={override} size={150} />
                </div>
                : ''
            }
            <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
                <h4 className="font-weight-bold m-0">Invoice</h4>

                <div className="form-row align-items-center justify-content-end">
                    <div className="col-auto my-1 position-relative">
                        <button className="btn btn-simple" onClick={() => setShowFilter((currentShow) => !currentShow)} >
                            Filter
                        </button>
                        {showFilter ? (
                            <div className="filter-container p-4 bg-white shadow-sm rounded">
                                <Row>
                                    <Col md="6" className="mb-4">
                                        <InputDate
                                            id="date"
                                            placeholder="Dates"
                                            label="Dates"
                                            value={invoiceDate}
                                            onChange={dateHandleChange}
                                        />

                                    </Col>
                                    <Col md="6" className="mb-4">
                                        <Dropdown
                                            id="statuses"
                                            placeholder="Status"
                                            label="name"
                                            value={status}
                                            options={statuses}
                                            onChange={(event, newValue) => {
                                                setStatus(newValue);
                                            }}
                                        />
                                    </Col>

                                    <Col md="6" className="mb-4">
                                        <Dropdown
                                            id="projects"
                                            placeholder="Project"
                                            label="name"
                                            value={project}
                                            options={projects}
                                            onChange={(event, newValue) => {
                                                setProject(newValue);
                                            }}
                                        />
                                    </Col>
                                    <Col md="6" className="mb-4">
                                        <Dropdown
                                            id="parties"
                                            placeholder="Party"
                                            label="name"
                                            value={party}
                                            options={parties}
                                            onChange={(event, newValue) => {
                                                setParty(newValue);
                                            }}
                                        />
                                    </Col>
                                    <Col md="6" className="mb-4">
                                        <Dropdown
                                            id="jobtypes"
                                            placeholder="Job Type"
                                            label="type"
                                            value={jobtype}
                                            options={jobtypes}
                                            onChange={(event, newValue) => {
                                                setJobtype(newValue);
                                            }}
                                        />
                                    </Col>
                                    <Col md="6" className="mb-4">
                    <Dropdown
                      id="invoicenumber"
                      placeholder="Invoice Number"
                      label="invoice_number"
                      value={filterInvoicenumber}
                      options={invoiceIds}
                      onChange={(event, newValue) => {
                        setFilterInvoicenumber(newValue);
                      }}
                    />
                  </Col>
                  <Col md="6" className="mb-4">
                                        <InputDate
                                              id="date"
                                              placeholder="Date of Execution Start"
                                              label="Date of Execution Start"
                                            value={startDate}
                                            onChange={(event) => {
                                                setStartDate(event.target.value);
                                              }}
                                        />

                                    </Col>
                                    <Col md="6" className="mb-4">
                                        <InputDate
                                            id="date"
                                            placeholder="Date of Execution End"
                                            label="Date of Execution End"
                                            value={endDate}
                                            onChange={(event) => {
                                                setEndDate(event.target.value);
                                              }}
                                        />

                                    </Col>
                                </Row>
                                <div className="text-right border-top pt-3">
                                    <Button
                                        className="my-0 mr-3"
                                        variant="secondary"
                                        onClick={filterClear}
                                    >
                                        Clear
                                    </Button>
                                    <Button
                                        className="my-0 mr-3"
                                        variant="secondary"
                                        onClick={filterClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="my-0  "
                                        variant="primary"
                                        onClick={filterSubmit}
                                    >
                                        Apply
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="col-auto my-1">
                        <button className="btn btn-primary" onClick={handleShow}>
                            Create Invoice
                        </button>
                    </div>
                </div>

                {/* ******************** modal start here **************** */}

                <Modal show={show} onHide={handleClose} size="xl" >
                    <Modal.Header className="p-4">
                        <Modal.Title className="m-0">{formName} Invoice </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <Row className="mb-4">
                            <Col md="6" className="my-4">
                                <Dropdown
                                    id="parties"
                                    placeholder="Party"
                                    label="name"
                                    value={selectedParty}
                                    options={parties}
                                    onChange={(event, newValue) => {
                                        setSelectedParty(newValue);
                                    }}
                                    formError={(formError && formError.errors.party_id) ? formError.errors.party_id : ''}
                                />
                            </Col>
                            <Col md="6" className="my-4">
                                <Dropdown
                                    id="job"
                                    placeholder="Job number"
                                    label="job_id"
                                    options={jobs}
                                    value={selectedJob}
                                    onChange={(event, newValue) => {
                                        setShowDestination(false);
                                        getCostsOfJob(newValue.id);
                                        findMultipleAirwaybills(newValue.id);
                                        setSelectedJob(newValue);
                                        setSelectedParty('');
                                        setSelectedProject('');
                                    }}
                                    formError={(formError && formError.errors.job_id) ? formError.errors.job_id : ''}
                                />
                            </Col>
                            <Col md="6" className="my-4">
                                <Dropdown
                                    id="project"
                                    placeholder="Project"
                                    label="name"
                                    value={selectedProject}
                                    options={projects}
                                    onChange={(event, newValue) => {
                                        setSelectedProject(newValue);
                                    }}
                                    formError={(formError && formError.errors.project_id) ? formError.errors.project_id : ''}
                                />
                            </Col>
                            <Col md="6" className="my-4">
                                <Dropdown
                                    id="currency"
                                    placeholder="Invoice Currency"
                                    label="currency_label"
                                    value={invoiceCurrency}
                                    options={currencys}
                                    onChange={(event, newValue) => {
                                        setInvoiceCurrency(newValue);
                                        getCurrencyConvertion(newValue.currency_label);
                                    }}
                                    formError={(formError && formError.errors.currency_id) ? formError.errors.currency_id : ''}
                                />
                            </Col>
                            <Col md="6" className="my-4 form-group">
                                <InputDate
                                    id="date"
                                    placeholder="Dates"
                                    label="Dates"
                                    value={invoiceDate}
                                    onChange={dateHandleChange}
                                    formError={(formError && formError.errors.invoice_date) ? formError.errors.invoice_date : ''}
                                />
                            </Col>
                            <Col md="6" className="my-4 form-group">
                                <InputField
                                    id="our-reference"
                                    placeholder="Our Referencesss"
                                    label="Our Reference"
                                    value={ourReference}
                                    onChange={ourReferenceHandleChange}
                                />

                            </Col>
                            {showDestination ? <Col md="6" className="my-4 form-group">
                                <Dropdown
                                    id="ports"
                                    placeholder="Destination"
                                    label="code"
                                    value={destination}
                                    options={ports}
                                    onChange={(event, newValue) => {
                                        setDestination(newValue);
                                    }}
                                />
                                <p className="text-danger small pt-2">
                                    Found multiple airwaybills so choose Destination
                                </p>
                            </Col> : ''}
                        </Row>
                        {
                            costs !== null && !costs.length && (
                                <h6 className="text-danger">Sorry, No Costs found for selected Job!</h6>
                            )
                        }
                        {
                            costs !== null && costs.length !== 0 && (
                                <Table responsive>
                                    <thead >
                                        <tr>
                                            <th>Revenue Description </th>
                                            <th>Unit </th>
                                            <th>Amount  </th>
                                            <th>VAT%  </th>
                                            <th>VAT Amount   </th>
                                            <th className="text-right">Total  </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            costs.map((element, index) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td colSpan="100%">
                                                                <div className="bg-light font-weight-bold p-2 border">{element.order_no}</div>
                                                            </td>
                                                        </tr>

                                                        {
                                                            element.costs && element.costs.map((costDetail, i) => {

                                                                if (costDetail.revenue_currency != null && costDetail.revenue_total != null && costDetail.revenue_total > 0) {

                                                                    if (conversionRate[costDetail.revenue_currency.currency_label]) {
                                                                        console.log(conversionRate[costDetail.revenue_currency.currency_label]);
                                                                        con_revenue_amount = costDetail.revenue_amount * conversionRate[costDetail.revenue_currency.currency_label];
                                                                        con_revenue_total = costDetail.revenue_total * conversionRate[costDetail.revenue_currency.currency_label];
                                                                    } else {

                                                                        con_revenue_amount = costDetail.revenue_amount;
                                                                        con_revenue_total = costDetail.revenue_total;

                                                                    }

                                                                    let unit = costDetail.cost_unit ? costDetail.cost_unit : 1;

                                                                    let amount = parseFloat(con_revenue_amount * unit).toFixed(2);
                                                                    let totalAmount = parseFloat(con_revenue_total).toFixed(2);
                                                                    let vatAmount = amount * (costDetail.revenue_vat / 100);
                                                                    let vatPercentage = costDetail.revenue_vat;

                                                                    totalAmountBeforeVat = (parseFloat(totalAmountBeforeVat) + parseFloat(amount));
                                                                    totalAmountAfterVat = (parseFloat(totalAmountAfterVat) + parseFloat(totalAmount));
                                                                    totalVatAmount = (parseFloat(totalVatAmount) + parseFloat(vatAmount));

                                                                    // let amount = parseFloat(costDetail.revenue_amount).toFixed(2);
                                                                    // let totalAmount = parseFloat(costDetail.revenue_total).toFixed(2);
                                                                    // let vatAmount = amount * (costDetail.revenue_vat / 100);
                                                                    // let vatPercentage = costDetail.revenue_vat;

                                                                    // totalAmountBeforeVat = (parseFloat(totalAmountBeforeVat) + parseFloat(amount));
                                                                    // totalAmountAfterVat = (parseFloat(totalAmountAfterVat) + parseFloat(totalAmount));
                                                                    // totalVatAmount = (parseFloat(totalVatAmount) + parseFloat(vatAmount));

                                                                    // console.log(totalAmountBeforeVat, totalAmountAfterVat, totalVatAmount);

                                                                    return (
                                                                        <tr>
                                                                            <td>
                                                                                <div className="form-group">
                                                                                    {costDetail.cost_description.name}

                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="form-group">
                                                                                    {costDetail.cost_unit}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="form-group">
                                                                                    {Number(amount).toFixed(2)}

                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="form-group">
                                                                                    {vatPercentage}

                                                                                </div>
                                                                            </td>

                                                                            <td>
                                                                                {Number(vatAmount).toFixed(2)}
                                                                            </td>
                                                                            <td className="text-right">
                                                                                {Number(totalAmount).toFixed(2)}
                                                                            </td>
                                                                            <td width={150}></td>

                                                                        </tr>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    </>
                                                );
                                            })
                                        }
                                    </tbody>
                                </Table>
                            )
                        }
                        {
                            costs !== null && costs.length >= 1 && (
                                <Table responsive className="text-right table-borderless border-bottom border-top">
                                    <tbody>
                                        <tr>
                                            <td > Total Amount before VAT </td>
                                            <td width={150}> {parseFloat(totalAmountBeforeVat).toFixed(2)} </td>

                                        </tr>
                                        <tr>
                                            <td>  VAT </td>
                                            <td> {parseFloat(totalVatAmount).toFixed(2)} </td>

                                        </tr>
                                        <tr>
                                            <td> Total Amount  </td>
                                            <td> {parseFloat(totalAmountAfterVat).toFixed(2)} </td>

                                        </tr>
                                    </tbody>
                                </Table>
                            )
                        }
                        {formError &&
                            formError.errors &&
                            formError.errors.common && (
                                <p className="text-danger small pt-2">
                                    {formError.errors.common}
                                </p>
                            )}
                    </Modal.Body>
                    <Modal.Footer className="p-4">
                        <Button className="mr-3" variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={onInvoiceSubmit}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* ******************** delete modal start here **************** */}
                <Modal show={showDelete} onHide={handleCloseDelete} size="lg">
                    <Modal.Header className="p-4">
                        <Modal.Title className="m-0">Delete Invoice </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <Modal.Title className="m-0">Are you sure to do this ? </Modal.Title>
                        <p>{deleteMessage}</p>
                    </Modal.Body>
                    <Modal.Footer className="p-4">
                        <Button
                            className="mr-3"
                            variant="secondary"
                            onClick={handleCloseDelete}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={deleteSubmitHandler}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>

            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar numSelected={selected.length} />
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={invoices.length}
                            />
                            <TableBody>
                                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                                {stableSort(invoices, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, row.id)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                            >

                                                <TableCell component="th" id={labelId} scope="row" padding="none" > {row.invoice_number} </TableCell>
                                                <TableCell>{row.job_type.type}</TableCell>
                                                <TableCell>{row.project.name}</TableCell>
                                                <TableCell>{row.party.name}</TableCell>
                                                <TableCell>{row.invoice_date}</TableCell>
                                                <TableCell>{row.currency.currency_label} {Number(row.amount).toFixed(2)}</TableCell>
                                                <TableCell> {Number(row.received_amount).toFixed(2)}</TableCell>
                                                <TableCell> 
                                                    {/* {row.payment_reference} */}
                                                    <Col md="12" className=" form-group">
    <InputField
        id={`payment-reference-${row.id}`}
        placeholder=""
        label=""
        value={row.payment_reference=== null ? '' : row.payment_reference}
        onChange={(event) => paymentReferenceHandleChange(event, row.id)}
        onBlur={(event) => handlePaymentReferenceBlur(row.id,event.target.value )}
    />
</Col>
                                                    </TableCell>
                                                <TableCell style={{ width: '400px' }}>
                                                    {/* <span className="badge badge-danger py-1 px-2 ">{row.status.name}</span>  */}
                                                    <Col md="12" className="form-group">
        <Dropdown
            id="statuses"
            placeholder=""
            label="name"
            options={statuses}
            value={statuses.find(status => status.id === row.status.id)}
            onChange={(event, newValue) => {
                setStatus(newValue);
                // Make an API call to update the status here
                // You can call a function to update the status in the backend
                updateStatus(row.id, newValue.id);
            }}
            formError={(formError && formError.errors.status_id) ? formError.errors.status_id : ''}
        />
    </Col> 
                                                     </TableCell>
                                                <TableCell>
                                                    <button onClick={() => onEditClickHandler(row)} className="btn btn-primary btn-sm font-weight-bold ">Edit</button>
                                                    <button onClick={() => onPrintClickHandler(row)} className="btn btn-info btn-sm font-weight-bold ">Print</button>
                                                    <button onClick={() => onDeleteClickHandler(row)} className="btn btn-danger btn-sm font-weight-bold ">Delete</button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={invoices.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>

            </Box>
        </div>
    );
}
